<template>
    <div class="container">
        <div class="checkin">
            <el-row :gutter="20">
                <el-col :span="5">
                    <memberleft :activeIndex="leftPannelSelected"/>
                </el-col>
                <el-col :span="19">
                    <div class="right_pannel" style="padding-top:0px">
                        <div class="pannel-top" style="width:100%;height:150px;">
                            <img src="https://static.a6air.com/resource/banner/20210330/pannel_top_member.jpg" alt="">
                        </div>
                        <div style="display: flex; justify-content: center">
                            <!-- <el-tabs value="userInfo" style="width: 90%"> -->
                            <!-- <el-tab-pane label="个人中心" name="userInfo"> -->
                            <div style="display: flex; justify-content: center">
                                <ul class="service-forms">
                                    <!-- <li class="form-group">
                                                <label class="form-label">手机号：</label>
                                                <el-input id="phone" ref="phone" v-model="registerData.phone"
                                                          :disabled="disableUpdate" placeholder="请输入手机号"
                                                          style="width:350px"></el-input>
                                    </li>-->
                                    <li class="form-group">
                                        <label class="form-label">证件类型：</label>
                                        <el-select
                                            id="certificateType"
                                            v-model="registerData.certificateType"
                                            :disabled="disableUpdate"
                                            placeholder="请选择"
                                            style="width:350px"
                                        >
                                            <el-option
                                                v-for="item in certTypeOptions"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value"
                                            ></el-option>
                                        </el-select>
                                    </li>
                                    <li class="form-group">
                                        <label class="form-label" for="certificateNo">证件号：</label>
                                        <div style="display: inline-block;">
                                          <el-input
                                              v-show="certEditing"
                                              id="certificateNo"
                                              ref="certificateNo"
                                              :disabled="disableUpdate"
                                              v-model="registerData.certificateNo"
                                              placeholder="请输入证件号"
                                              @blur="certEditing=false"
                                              style="width:350px"
                                          >
                                          </el-input>
                                          <div v-show="!certEditing" :class="{'eye-cert-wrap':true,disabled:disableUpdate}" style="width:350px;">
                                            <span style="flex:1;" @click="onEditCert">{{maskCertNo}}</span>
                                            <div class="suffix-icon">
                                              <img class="eye-icon" @click="maskCert=!maskCert" v-if="!maskCert" src="https://static.a6air.com/sale/eye.png"/>
                                              <img class="eye-icon" @click="maskCert=!maskCert" v-if="maskCert" src="https://static.a6air.com/sale/eye-close.png"/>
                                            </div>
                                          </div>
                                        </div>
                                    </li>
                                    <li class="form-group">
                                        <label class="form-label" for="userTrueName">姓名：</label>
                                        <el-input
                                            id="userTrueName"
                                            ref="userTrueName"
                                            :disabled="disableUpdate"
                                            v-model="registerData.name"
                                            placeholder="请输入姓名"
                                            style="width:350px"
                                        ></el-input>
                                    </li>
                                    <li class="form-group">
                                        <label class="form-label" for="gender">称谓：</label>
                                        <div style="width: 350px; display: inline-flex">
                                            <el-radio-group
                                                id="gender"
                                                :disabled="disableUpdate"
                                                v-model="registerData.sex"
                                            >
                                                <el-radio label="M">先生</el-radio>
                                                <el-radio label="F">女士</el-radio>
                                            </el-radio-group>
                                        </div>
                                    </li>
                                    <li class="form-group" style="display: none;">
                                        <label class="form-label" id="birthDay" for="birthDay">出生日期：</label>
                                        <el-date-picker
                                            :disabled="disableUpdate"
                                            v-model="registerData.birthDay"
                                            type="date"
                                            placeholder="请选择出生日期"
                                            style="width:350px"
                                            value-format="YYYY-MM-DD"
                                            :picker-options="pickerOptions"
                                        ></el-date-picker>
                                    </li>
                                    <li class="form-group">
                                        <label class="form-label" for="email">邮箱：</label>
                                        <el-input
                                            id="email"
                                            ref="email"
                                            :disabled="disableUpdate"
                                            v-model="registerData.email"
                                            placeholder="请输入邮箱"
                                            style="width:350px"
                                        ></el-input>
                                    </li>
                                    <li class="form-group">
                                        <label class="form-label" for="area">地址：</label>
                                        <el-cascader
                                            id="area"
                                            v-model="registerData.area"
                                            :disabled="disableUpdate"
                                            placeholder="请选择地区"
                                            :props="{ expandTrigger: 'hover' }"
                                            :options="countryData"
                                            :clearable="true"
                                            :filterable="true"
                                            style="width:350px"
                                        ></el-cascader>
                                    </li>
                                    <li class="form-group">
                                        <label class="form-label" for="address"></label>
                                        <el-input
                                            id="address"
                                            ref="address"
                                            :disabled="disableUpdate"
                                            v-model="registerData.address"
                                            placeholder="请输入详细地址"
                                            style="width:350px"
                                        ></el-input>
                                    </li>
                                    <!-- <li class="form-group" style="display: inline-flex">
                                        <label class="form-label" for="picValidateCode">验证码：</label>
                                        <el-input
                                            id="picValidateCode"
                                            :clearable="true"
                                            v-model="registerData.picValidateCode"
                                            ref="picValidateCode"
                                            placeholder="请输入图形验证码"
                                            style="width:170px;height: 40px!important "
                                        ></el-input>
                                        <el-image
                                            style="width: 100px;padding-left: 10px;"
                                            :src="picValidateUrl"
                                            @click="changeValidateCode"
                                        ></el-image>
                                        <div @click="changeValidateCode" class="change-btn">看不清，换一张</div>
                                    </li> -->
                                    <!-- <li class="form-group" style="display:flex">
                                        <label class="form-label" for="phoneValidateCode">短信验证码：</label>
                                        <el-input
                                            id="phoneValidateCode"
                                            ref="phoneValidateCode"
                                            v-model="registerData.phoneValidateCode"
                                            placeholder="请输入验证码"
                                            style="width:170px;display:none"
                                        >
                                        </el-input>
                                        <div
                                            style="margin-left: 10px;"
                                            class="validate-btn"
                                            @click="fetchPhoneValidateCodeHandler"
                                            v-loading="callphoneloading"
                                            :disabled="(phoneCodeIntervalMax<=0 || phoneCodeIntervalMax>=60)?false:true"
                                        >{{phoneValidateCodeLabel}}</div>
                                    </li> -->
                                    <li>
                                        <div
                                            style="text-align:center;width:100%;padding-bottom:5px"
                                        >
                                            <el-button
                                                type="danger"
                                                style="width:300px;"
                                                @click="updateUserHandler"
                                                :class="[disableUpdate ? 'btn-color-grey' : '']"
                                            >修&nbsp;改</el-button>
                                        </div>
                                        <span class="fix-tip">
                                              仅有一次修改个人信息的机会，修改后可能导致飞行次数及成长值发生变更，请谨慎修改哦！
                                        </span>
                                    </li>
                                </ul>
                            </div>
                            <!-- </el-tab-pane> -->
                            <!-- <el-tab-pane label="鸿鹄会" name="memberInfo">
                                    <div style="display: flex; justify-content: center">
                                        <el-card style="width: 95%; margin-bottom: 20px">
                                            <detail-list title="基本信息">
                                                <detail-list-item :term="gradeText(registerData.grade)">
                                                    <el-tag v-show="registerData.nextGradePoint !== null" size="small"
                                                            type="success">
                                                        {{ '距离升级还差' + registerData.nextGradePoint + '积分' }}
                                                    </el-tag>
                                                </detail-list-item>
                                                <detail-list-item term="乘机次数">{{ `${registerData.flyCount}次` }}
                                                </detail-list-item>
                                                <detail-list-item term="实名认证">
                                                    {{ verifyStatusText(registerData.verifyStatus) }}
                                                    <el-tag v-if="!disableUpdate" small
                                                            style="margin-left: 10px; cursor:pointer;" type="warning"
                                                            @click="goVerify">去认证
                                                    </el-tag>
                                                </detail-list-item>
                                                <detail-list-item term="积分数">{{ `${registerData.point}分` }}
                                                </detail-list-item>
                                            </detail-list>
                                        </el-card>
                            </div>-->
                            <!--                                    <div style="display: flex; justify-content: center">-->

                            <!--                                        <el-card style="width: 95%; margin-bottom: 20px">-->
                            <!--                                            <detail-list title="权益">-->
                            <!--                                                <detail-list-item>测试权益</detail-list-item>-->
                            <!--                                            </detail-list>-->
                            <!--                                        </el-card>-->
                            <!--                                    </div>-->
                            <!-- </el-tab-pane> -->
                            <!-- </el-tabs> -->
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>
    </div>
</template>
<script>
import Vue from "vue";
import memberleft from "../../components/MemberLeft";
import { userinfo, fetchPhoneValidateCode, updateuser } from "@/api/newsapi";
import DetailList from "@/components/DescriptionList";
import countryData from "@/utils/country-level3-data.js";
import { CURRENT_USER } from "@/store/mutation-types";

const DetailListItem = DetailList.Item;

export default {
  // components: {memberleft, DetailListItem, DetailList},
  components: { memberleft },
  data() {
    return {
      maskCert:true,
      input: "",
      callphoneloading: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      phoneValidateCodeLabel: "获取验证码",
      phoneCodeIntervalMax: 60,
      leftPannelSelected: "userinfo",
      pwdStrong: 0,
      countryData: countryData,
      baseValidateUrl: "/api/user/createCaptcha?random=",
      picValidateUrl: "",
      validateCodeRequest: {
        phone: "",
        picValidateCode: "",
        action: "",
        sendType: "updateuserinfo"
      },
      disableUpdate: false,
      registerData: {
        name: "",
        certificateType: "",
        certificateNo: "",
        area: [],
        gender: "",
        verifyStatus: "",
        nextGrade: "",
        birthday: "",
        address: "",
        flyCount: "",
        point: "",
        mileage: "",
        sex: "",
        email: "",
        phone: "",
        phoneValidateCode: "",
        picValidateCode: "",
        checked: false
      },
      certTypeOptions: [
        {
          value: "NI",
          label: "身份证"
        },
        {
          value: "PP",
          label: "护照"
        },
        {
          value: "GM",
          label: "军官证"
        },
        {
          value: "HX",
          label: "回乡证"
        },
        {
          value: "TB",
          label: "台胞证"
        },
        {
          value: "TW",
          label: "台湾通行证"
        },
        {
          value: "HY",
          label: "海员证"
        },
        {
          value: "QT",
          label: "其它"
        }
      ],
      verifyStatusOptions: [
        {
          value: "NOT_CERTIFIED",
          label: "未认证"
        },
        {
          value: "CERTIFIED_FAILED",
          label: "认证失败"
        },
        {
          value: "CERTIFIED_SUCCESS",
          label: "已认证"
        },
        {
          value: "WAITING_CERTIFIED",
          label: "认证中"
        }
      ],
      gradeOptions: [
        {
          value: "BRONZE",
          label: "鸿鹄普卡"
        },
        {
          value: "SILVER",
          label: "鸿鹄银卡"
        },
        {
          value: "GOLD",
          label: "鸿鹄金卡"
        },
        {
          value: "DIAMOND",
          label: "鸿鹄白金卡"
        }
      ],

      maskCertNo:null,
      certEditing:false
    };
  },
  watch:{
    'registerData.certificateNo'(val){
      this.maskCertNo = this.maskCert?this.maskStr(val):val;
    },
    maskCert(mask){
      this.maskCertNo = this.maskCert?this.maskStr(this.registerData.certificateNo):this.registerData.certificateNo;
    }
  },
  created() {
    this.picValidateUrl = this.baseValidateUrl + new Date().getTime();
  },
  mounted() {
    this.currentUser = Vue.ls.get(CURRENT_USER);
    if (
      !this.currentUser ||
      this.currentUser.phone == undefined ||
      this.currentUser.phone.length <= 0
    ) {
      this.$router.push({ path: "/login", query: { t: new Date().getTime() } });
    }
    this.query();
  },
  methods: {
    onEditCert(){
      if(this.disableUpdate){
        return;
      }
      this.certEditing=true;
    },
    maskStr(data){
      if(!data){
        return data;
      }
      else if(data.length==18){
        return data.replace(/^(.{6})(?:.+)(.{4})$/, '$1********$2')
      }else if(data.length>4){
        const dots = new Array(data.length-4);
        dots.fill('*')
        return data.replace(/^(.{2})(?:.+)(.{2})$/,['$1',dots.join(''),'$2'].join(''))
      }
      else{
        return data;
      }
    },
    query() {
      var self = this;
      userinfo({ t: {} }).then(res => {
        if (res.success) {
          self.registerData.name = res.data.name;
          self.registerData.certificateType = res.data.certificateType;
          self.registerData.certificateNo = res.data.certificateNo;
          self.registerData.area = res.data.area;
          self.registerData.verifyStatus = res.data.verifyStatus;
          self.registerData.nextGrade = res.data.nextGrade;
          self.registerData.grade = res.data.grade;
          self.registerData.birthday = res.data.birthday;
          self.registerData.address = res.data.address;
          self.registerData.flyCount = res.data.flyCount;
          self.registerData.point = res.data.point;
          self.registerData.mileage = res.data.mileage;
          self.registerData.sex = res.data.sex;
          self.registerData.email = res.data.email;
          self.registerData.phone = res.data.phone;
          self.registerData.extInfo = res.data.extInfo;
          self.registerData.nextGradePoint = res.data.nextGradePoint;
          if (
            self.registerData.verifyStatus === "CERTIFIED_SUCCESS" ||
            self.registerData.verifyStatus === "WAITING_CERTIFIED"
          ) {
            self.disableUpdate = true;
          }
          if(self.registerData.extInfo['UPDATE_NUM']){
            self.disableUpdate = true;
          }
          Vue.ls.set(CURRENT_USER, res.data);
        }
      });
    },
    gradeText(grade) {
      for (let gradeOption of this.gradeOptions) {
        if (gradeOption.value === grade) {
          return gradeOption.label;
        }
      }
      return "";
    },
    verifyStatusText(status) {
      for (let verifyStatusOption of this.verifyStatusOptions) {
        if (verifyStatusOption.value === status) {
          return verifyStatusOption.label;
        }
      }

      return "";
    },
    goVerify() {
      if (
        this.currentUser.certificateNo === undefined ||
        this.currentUser.certificateNo.length < 0
      ) {
        this.$alert("请先到个人中心完善证件信息");
        return;
      }
      this.$router.push({ path: "/member/verify" });
    },
    fetchPhoneValidateCodeHandler() {
      if (this.registerData.phone.length != 11) {
        this.$message.error("手机号码校验不通过，请核实输入11位手机号");
        this.$refs.phone.focus();
        return;
      }
      if (this.registerData.picValidateCode.length < 4) {
        this.$message.error("请先输入图像验证码!");
        this.$refs.picValidateCode.focus();
        return;
      }
      this.validateCodeRequest.phone = this.registerData.phone;
      this.validateCodeRequest.picValidateCode = this.registerData.picValidateCode;
      var that = this;
      this.callphoneloading = true;
      fetchPhoneValidateCode(this.validateCodeRequest).then(res => {
        this.callphoneloading = false;
        if (res.success) {
          that.phoneCodeIntevel();
        } else {
          that.$message.error(res.errorMessage);
        }
      });
    },
    changeValidateCode() {
      this.picValidateUrl = this.baseValidateUrl + new Date().getTime();
    },
    updateUserHandler() {
      console.log(this.registerData);
      if(this.disableUpdate){
        return;
      }
      if (this.registerData.phoneValidateCode.length != 6) {
        //this.$message.error("短信验证码输入不正确,请重新输入");
        //this.$refs.phoneValidateCode.focus();
        //return;
      }
      updateuser(this.registerData).then(res => {
        if (res.success) {
          this.$message({
            message: "修改个人信息成功!",
            type: "success"
          });
          setTimeout(() => {
            window.location.reload();
          }, 500);
        } else {
          this.$message.error(res.errorMessage);
        }
        this.query();
      });
    },
    selectPannel(pannelId) {
      document.getElementById("li_passlist").setAttribute("class", "");
      document.getElementById("li_order").setAttribute("class", "");
      document.getElementById("li_changepwd").setAttribute("class", "");
      document.getElementById("li_userinfo").setAttribute("class", "");
      document.getElementById(pannelId).setAttribute("class", "active");
    },
    phoneCodeIntevel() {
      var that = this;
      console.log("倒计时");
      that.phoneCodeIntervalMax = 60;
      var interval = setInterval(function() {
        console.log("倒计时");
        var remainSecond = that.phoneCodeIntervalMax--;
        if (remainSecond <= 0) {
          clearInterval(interval);
          that.phoneValidateCodeLabel = "获取验证码";
          return;
        }
        that.phoneValidateCodeLabel = remainSecond + "秒后重新获取";
      }, 1000);
    }
  }
};
</script>
<style scoped lang='less'>
.form-group .form-label {
  width: 100px;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  line-height: 35px;
  color: #4d4e4e;
  text-align: right;
}

.checkin-links.query {
  margin: 24px 35px 24px 75px !important;
}

.form-group .form-required {
  margin-left: 8px;
  font-size: 26px;
  font-weight: 700;
  padding-top: 4px;
  color: #e34c4c;
  display: inline-block;
  height: 35px;
  line-height: 35px;
  vertical-align: middle;
}

.checkin {
  margin-top: 20px;
  margin-bottom: 20px;
}

.left_pannel {
  height: 500px;
  background-color: #f5f5f5;
  overflow: hidden;
}

.right_pannel {
  position: relative;
  background-color: #ffffff;
  /* border: 1px solid #ededed; */
}

.col_menu b {
  font-size: 24px;
  margin: 20px 20px 0;
  color: #333;
  display: block;
  font-weight: normal;
  border-bottom: 1px solid #f1f1f1;
}

.col_menu ul {
  margin-bottom: 20px;
}

ul,
ol,
dl,
dd,
dt {
  margin: 0;
  padding: 0;
  display: block;
}

.col_menu li {
  margin: 10px 0;
  position: relative;
}

.col_menu li.current {
  background: none;
}

.col_menu li.current a:link,
.col_menu li.current a:visited,
.col_menu li.current a:active {
  border-left: 2px #ff6600 solid;
  color: #666;
  font-weight: bold;
}

.col_menu li a:link,
.col_menu li a:visited,
.col_menu li a:active {
  display: block;
  line-height: 30px;
  padding: 0 55px 0 20px;
}

a:link,
a:visited,
a:active {
  color: #666;
  text-decoration: none;
}

a {
  color: #337ab7;
  text-decoration: none;
}

.warm-prompt {
  padding: 40px 50px 80px;
}

.warm-prompt p {
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  color: #9d9d9d;
  text-align: left;
}

.order-search .service-title {
  font-size: 18px;
  font-weight: 700;
  line-height: 55px;
  color: #4d4e4e;
  text-align: center;
  border-bottom: 1px solid #eae7e7;
}

.order-search .service-prompt {
  padding-top: 8px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.8;
  color: #9d9d9d;
  text-align: center;
}

.order-search .service-forms > li {
  margin-top: 20px;
}

.form-group .form-label {
  width: 100px;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  line-height: 35px;
  color: #4d4e4e;
  text-align: right;
}

.checkin-links.query {
  margin: 24px 35px 24px 75px !important;
}

.agree-protocol {
  margin: 24px 0;
  padding-left: 24px;
  font-size: 0;
}

.link.link-sm {
  height: 18px;
  font-size: 12px;
  line-height: 18px;
}

.link {
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #2c6e8b;
  text-align: left;
}

.search-block {
  width: 1200px;
  margin: auto;
  position: relative;
}

.popcontext .tab {
  z-index: 1000;
  width: 240px;
}

.popcontext .tab li {
  height: 60px;
  line-height: 60px;
  /* border-top: 1px solid #4e617f; */
  background-color: #f5f5f5;
  color: #000000;
  font-size: 14px;
  text-align: center;
  transition: all 0.5s ease 0s;
  -webkit-transition: all 0.5s ease 0s;
  cursor: pointer;
  margin-left: 10px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  filter: alpha(Opacity=60);
  -moz-opacity: 0.6;
  opacity: 0.6;
}

.popcontext .tab li:last-child {
  -webkit-border-bottom-left-radius: 5px;
  -moz-border-bottom-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.popcontext .tab li.active {
  /* border-top: 1px solid #fff; */
  background-color: #d6003a;
  font-size: 16px;
  color: #ffffff;
  font-weight: bold;
  margin-left: 0;
  padding-left: 10px;
  border-right: 1px solid #fff;
  -webkit-border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px;
  filter: alpha(Opacity=100);
  -moz-opacity: 1;
  opacity: 1;
}

.popcontext hgroup {
  display: none;
  position: absolute;
  width: 460px;
  top: -410px;
  left: 0px;
  z-index: 1000;
  margin-left: 140px;
  background-color: #fff;
  -webkit-border-radius: 0 5px 5px 0;
  -moz-border-radius: 0 5px 5px 0;
  border-radius: 0 5px 5px 0;
}

.popcontext hgroup article {
  position: relative;
  height: 364px;
  padding-left: 30px;
}

.service-query .service-title {
  font-size: 18px;
  font-weight: 700;
  line-height: 55px;
  color: #4d4e4e;
  text-align: center;
  border-bottom: 1px solid #eae7e7;
}

.service-query .service-prompt {
  padding-top: 8px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.8;
  color: #9d9d9d;
  text-align: center;
}

.service-forms > li {
  margin-top: 15px;
}

.strength-container .strength-bar {
  display: block;
  padding: 2px;
  height: 9px;
  border: 1px solid #b7b7b7;
  border-radius: 8px;
}

.strength-container .strength-bar div {
  display: block;
  float: left;
  width: 60px;
  height: 8px;
  background-color: #cdcdcd;
}

.strength-container .strength-bar div:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.strength-container .strength-bar div:nth-child(2) {
  margin: 0 3px;
}

.strength-container .strength-bar div:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.strength-container .strength-text {
  padding: 4px 0 0;
  font-size: 12px;
  font-weight: normal;
  line-height: 1;
  color: #4d4e4e;
  text-align: center;
}

.strength-container .strength-text span {
  display: inline-block;
  width: 33.33%;
}

.strength-container.weak > .strength-bar div:first-child {
  background-color: #eb3701;
}

.strength-container.middle > .strength-bar div:first-child {
  background-color: #eb3701;
}

.strength-container.middle > .strength-bar div:nth-child(2) {
  background-color: #f88214;
}

.strength-container.strong > .strength-bar div:first-child {
  background-color: #eb3701;
}

.strength-container.strong > .strength-bar div:nth-child(2) {
  background-color: #f88214;
}

.strength-container.strong > .strength-bar div:last-child {
  background-color: #6aa001;
}
.pannel-top {
  // background-image: url("../../assets/prod-new.png");
  // border-radius: 10px 10px 0 0;
  // background-size: 100% 100%;
  img{
    width: 100%;
    height: 150px;
    border-radius: 10px 10px 0 0;
  }

}
.change-btn {
  text-align: left;
  font-size: 12px;
  margin-left: 10px;
  color: #009ed0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.validate-btn {
  width: 100px;
  height: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #d70039;
  cursor: pointer;
  border-radius: 5px;
  background: #ffffff;
  color: #4d4e4e;
}
.fix-tip{
  display: inline-block;
  height: 40px;
  line-height: 40px;
  color: #333;
  width: 500px;
  font-size: 12px;
}
.btn-color-grey{
  background-color: #BEBEBE !important;
  border: 0;
}
.suffix-icon{
  display: flex;
  height: 100%;
  align-items: center;
  padding-right: 6px;
}
.eye-icon{
  width: 20px;
  height: 20px;
  opacity: 0.4;
}
.eye-icon:hover{
  opacity: 0.8;
  cursor: pointer;
}

.eye-cert-wrap{
  width: 350px;
  display: flex;
  justify-content: space-between;
  height: 40px;
  line-height: 40px;
  border: solid 1px #e5e7ee;
  box-sizing: border-box;
  padding-left: 15px;
  border-radius: 5px;
}
.eye-cert-wrap.disabled{
  background: #f6f7fa;
  color: #c0c4cc;
}
</style>
